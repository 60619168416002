import React, { ReactElement } from 'react';
import parse from 'react-html-parser';

import './BlogPostCard.scss';

interface Props {
  data: {
    IntroText: string;
    Title: string;
    readTime: string;
    pageUrl: string;
    heroSection: {
      mainImg: {
        url: string;
      };
    };
    publishData: string;
  };
}

const BlogPostCard = (props: Props): ReactElement => {
  const { IntroText, Title, readTime, heroSection, publishData, pageUrl } = props.data;

  // format date into array and english format
  const formatDate = publishData.toString().split(' ');

  return (
    <>
      <li className="blogCard">
        <a className="cardLink" href={`/blogs/${pageUrl}`}>
          <div className="cardDate">
            <div className="day">{formatDate[2]}</div>
            <div className="month">{formatDate[1]}</div>
            <div className="year">{formatDate[3]}</div>
          </div>
          <img className="blogImg" src={heroSection.mainImg.url} alt="" />
          <div className="cardText">
            <h5>{Title}</h5>
            <p className="tags">
              <img
                src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-tag-red.svg"
                alt="tag"
              />
              driving&nbsp;&nbsp;
              <span>
                <img
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-clock-red.svg"
                  alt="clock"
                />
                {readTime}
              </span>
            </p>
            <p className="introText">{parse(IntroText)}</p>
            <p className="redText">Read more</p>
          </div>
        </a>
      </li>
    </>
  );
};

export default BlogPostCard;
