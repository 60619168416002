import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import React, { ReactElement } from 'react';

import './FeatureBlog.scss';

interface Props {
  data: {
    strapi: {
      articles: {
        map: (post: {}) => any;
        reverse: () => any;
        id: string;
        IntroText: string;
        Title: string;
        readTime: string;
        publishData: string;
        heroSection: {
          mainImg: {
            url: string;
          };
        };
      };
      blogIndex: {
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          iconImg: {
            url: string;
          };
          lozengeText;
          mainImg: {
            url: string;
          };
        };
        FeatureblogArticleDetails: {
          BlogCategory: string;
          ButtonLink: string;
          ButtonText: string;
          FirstLineOfBlog: string;
          PublishDate: string;
          ReadTime: string;
        };
      };
    };
  };
}

const FeatureBlog = (props: Props): ReactElement => {
  const {
    HeroSection,
    FeatureblogArticleDetails,
  } = props.data.strapi.blogIndex;

  const formatedDate = FeatureblogArticleDetails.PublishDate.split(
    `-`,
  ).reverse();

  return (
    <>
      <GeneralHeroSec data={HeroSection} />
      <section className="articleDetails">
        <div className="articleDetailsWrapper">
          <h2>{FeatureblogArticleDetails.FirstLineOfBlog}</h2>
          <p>
            <img
              src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-calendar-red.svg"
              alt="calendar"
            />
            {`${formatedDate[0]}/${formatedDate[1]}/${formatedDate[2]}`}
            &nbsp;&nbsp;
            <img
              src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-tag-red.svg"
              alt="tag"
            />
            {FeatureblogArticleDetails.BlogCategory}&nbsp;&nbsp;
            <span>
              <img
                src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-clock-red.svg"
                alt="clock"
              />
              {FeatureblogArticleDetails.ReadTime}
            </span>
          </p>
          <a href={FeatureblogArticleDetails.ButtonLink} className="btn">
            {FeatureblogArticleDetails.ButtonText}
          </a>
        </div>
      </section>
    </>
  );
};

export default FeatureBlog;
