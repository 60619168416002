import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import FeatureBlog from '../../components/FeatureBlog';
import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import BlogPostCard from '@/components/Cards/BlogPostCard';
import './BlogIndex.scss';
import SEO from '@/components/SEO/Seo';

interface Props {
  data: {
    strapi: {
      articles: {
        map: (post: {}) => any;
        reverse: () => any;
        id: string;
        IntroText: string;
        Title: string;
        readTime: string;
        publishData: string;
        pageUrl: string;
        heroSection: {
          mainImg: {
            url: string;
          };
        };
      };
      blogIndex: {
        Seo: {
          description: string;
          title: string;
        };
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          iconImg: {
            url: string;
          };
          lozengeText;
          mainImg: {
            url: string;
          };
        };
        FeatureblogArticleDetails: {
          BlogCategory: string;
          ButtonLink: string;
          ButtonText: string;
          FirstLineOfBlog: string;
          PublishDate: string;
          ReadTime: string;
        };
      };
    };
  };
}

const blog = ({ data }: Props) => {
  // OFR: articles comes back in random order each time so creating new array to push articles in with a format that can be sorted.
  const newPublishDate = []

  data.strapi.articles.map((article) => {
    //OFR: converts to string so can be sorted
    article.publishData = new Date(article.publishData)  
    //OFR: push new article into empty array   
    newPublishDate.push(article)
    }
  )
    //OFR: sort new array in date order
  const sortedPosts = newPublishDate.sort((a, b) => b.publishData - a.publishData)

  const {
    Seo
  } = data.strapi.blogIndex;

  return (
    <div>
      <SEO
        title={Seo.title}
        description={Seo.description}
      />
      <Layout>
        <div>
          <FeatureBlog data={data} />
          <section className="blogPostCont">
            <h3>More articles</h3>
            <ul className="listOfBlogs">
              {sortedPosts.map((post) => (
                <BlogPostCard key={post.id} data={post} />
              ))}
            </ul>
          </section>
          <SearchWidgetContainer />
        </div>
      </Layout>
    </div>
  );
};

export default blog;

export const query = graphql`
  query BlogIndexTest {
    strapi {
      articles(publicationState: PREVIEW) {
        id
        IntroText
        Title
        readTime
        pageUrl
        publishData
        heroSection {
          mainImg {
            url
          }
        }
      }
      blogIndex(publicationState: PREVIEW) {
        Seo {
          description
          title
        }
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          iconImg {
            url
          }
          lozengeText
          mainImg {
            url
          }
        }
        FeatureblogArticleDetails {
          BlogCategory
          ButtonLink
          ButtonText
          FirstLineOfBlog
          PublishDate
          ReadTime
        }
      }
    }
  }
`;
